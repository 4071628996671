const Fuse = require("fuse.js")

const Autocomplete = function(input, list, target) {
    this.input = input
    this.list = list
    this.target = target
    this.highlighted = 0

    autocomplete.style.display = "inline-block"
    list.style.display = "flex"
    list.style.left = input.getBoundingClientRect().left + "px"
    list.style.top = input.getBoundingClientRect().bottom + "px"
    target.style.display = "none"

    const options = target.querySelectorAll("option")
    this.elements = []
    for (let i = 0; i < options.length; i++) {
        this.elements.push({
            label: options[i].textContent,
            value: options[i].value
        })
    }

    const fuseOpts = {
        keys: ["label"],
        tokenize: true
    }
    this.fuse = new Fuse(this.elements, fuseOpts)

    if (this.elements.length === 1) {
        this.input.value = this.elements[0].label
        this.input.setAttribute("disabled", "disabled")
    }

    input.addEventListener("focus", e => {
        this.highlighted = 0
        this.update()
    })
    input.addEventListener("blur", e => {
        this.updateLabel()
    })
    input.addEventListener("keydown", e => {
        switch (e.key) {
            case "Enter":
                e.preventDefault()
                if (this.results && this.results[this.highlighted]) {
                    this.target.value = this.results[this.highlighted].value
                }
                this.hideOnce = true
                this.input.blur()
                this.input.focus()
                break
            case "Escape":
                this.hideOnce = true
                this.input.blur()
                this.input.focus()
                break
            case "ArrowDown":
                this.highlighted ++
                this.update()
                break
            case "ArrowUp":
                this.highlighted --
                this.update()
                break
        }

    })
    input.addEventListener("input",e => {
        this.update()
    })

    this.updateLabel(this.target.getAttribute("value"))
}

Autocomplete.prototype.update = function() {
    if (this.hideOnce) {
        this.hideOnce = false
        return
    }
    const results = this.fuse.search(this.input.value)
    this.results = results
    this.list.innerHTML = ""
    if (this.highlighted >= results.length) this.highlighted = results.length - 1
    if (this.highlighted < 0) this.highlighted = 0

    for (let i = 0; i < results.length && i < 5; i++) {
        const entry = document.createElement("div")
        entry.innerText = results[i].label
        if (i === this.highlighted) {
            entry.className = "highlighted"
        }
        entry.addEventListener("mousedown", (e) => {
            e.preventDefault()
            this.target.value = results[i].value
            this.hideOnce = true
            this.input.blur()
            this.input.focus()
        })
        this.list.appendChild(entry)
    }
}

Autocomplete.prototype.updateLabel = function(value) {
    this.list.innerHTML = ""
    value = typeof value !== "undefined" ?
        value :
        this.target.value
    const currentOption = this.elements.find(element => element.value === value)
    if (currentOption) {
        this.input.value = currentOption.label
    }
}

const autocomplete = document.querySelector(".autocomplete")
if (autocomplete) {
    const target = document.getElementById(autocomplete.getAttribute("data-target"))
    const list = document.querySelector(".autocomplete-list")
    new Autocomplete(autocomplete, list, target)
}
